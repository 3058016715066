<template>
    <ion-page>
        <ion-content :fullscreen="true" class="content" v-html="language.createTemplateInstructions"></ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonPage} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {useStore} from "vuex";
import {computed} from "vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    name: 'CreateTemplateInstructions',
    components: {
        IonContent,
        IonPage,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.templateCreation);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        
        return {
            store,
            language,
            footerButtons: computed(() => {
                return new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(() => router.push({name: "templateName"}))
                    .create();
            })
        }
    }
});
</script>

<style scoped>
.content >>> h2 {
    color: var(--ion-color-primary);
}
.content >>> p {
    font-size: 19px;
    line-height: 25px;
    font-weight: 200;
    color: var(--color-step-100);
}

.content >>> a {
    color: gray;
    font-weight: normal;
    line-height: 25px;
}

.content >>> ol {
    line-height: 25px;
    
}

.content >>> li {
    margin-bottom: 8px;
    font-weight:300;
}
</style>