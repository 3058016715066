

import {IonContent, IonPage} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {useStore} from "vuex";
import {computed} from "vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {defineComponent} from "@vue/runtime-core";

export default defineComponent({
    name: 'CreateTemplateInstructions',
    components: {
        IonContent,
        IonPage,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.templateCreation);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        
        return {
            store,
            language,
            footerButtons: computed(() => {
                return new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(() => router.push({name: "templateName"}))
                    .create();
            })
        }
    }
});
